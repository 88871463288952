import { DateTime } from "luxon";
import states from "../../state.json";
import { lookupTypes } from "../data/definitions/lookupTypes";
import { cleanDate } from "./date";

export const ftpGenderFormats = [
    {valueType: "1", name: "Initials: F / M / N"},
    {valueType: "2", name: "Full words: Female / Male / Other / Choose Not to Identify"}
];

export const ftpFileDelimeterFormats = [
    {valueType: "1", name: "Comma Delimited ( , )"},
    {valueType: "2", name: "Pipe Delimited ( | )"}
];

export const ftpMemberTypeFormats = [
    {valueType: "1", name: "Full words: Primary, Spouse, Adult Dependent, Child Dependent"},
    {valueType: "2", name: "Initials: M / S / D"}
];

export const getFilteredMemberType = (lookupStore, dependentList) =>
{
    let returnList = [];
    if (lookupStore.length < 1)
    {return returnList;} 
    const foundSpouse = dependentList.find(x => x.memberType == lookupTypes.memberType.spouse);
    if (foundSpouse)
    //if a spouse is found remove it from the drop down list.  
    {returnList = [...lookupStore.filter(x => x.lookupID != lookupTypes.memberType.primary && x.lookupID != lookupTypes.memberType.spouse)]}
    else 
    //only remove the primary from the drop down list.
    {returnList = [...lookupStore.filter(x => x.lookupID != lookupTypes.memberType.primary)]}
    return returnList;
}

export const getMemberType = (type) => {
    switch (type) {
        case 'Primary':
            return lookupTypes.memberType.primary
        //Only one allowed.
        case 'Spouse':
            return lookupTypes.memberType.spouse
        case 'Ward':
            return lookupTypes.dependentType.ward
        default:
            return lookupTypes.dependentType.dependent
    }
};
//gets the name of the member type by Guid.
export const getMemberTypeName = (type) => {
    switch (type) {
        case lookupTypes.memberType.primary:
            return 'Primary'
        //Only one allowed.
        case lookupTypes.memberType.spouse:
            return 'Spouse'
        case lookupTypes.dependentType.ward:
            return 'Adult Dependent'
        default:
            return 'Child'
    }
};
export const getGender = (letter) => {
    switch (letter.toUpperCase()) {
        case 'F':
            return lookupTypes.gender.female
        case 'M':
            return lookupTypes.gender.male
        default:
            return lookupTypes.gender.default
    }
}

export const getGenderName = (type) => {
    switch (type) {
        case lookupTypes.gender.female:
            return 'Female'
        case lookupTypes.gender.male:
            return 'Male'
        default:
            return 'None'
    }
}

export const convertToMemberObject = (externalId, firstName, lastName, gender,
    middleName, dob, type, email, phone, phone2, street1, street2, city, state, postal,
    language, country, product, valid, isPrimary, message) => {
    const member = {
        created: DateTime.now().toISO(),
        lastUpdated: DateTime.now().toISO(),
        isDeleted: false,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        email: email,
        gender: gender,
        phonePrimary: phone,
        phoneSecondary: phone2,
        memberType: type,
        dateOfBirth: cleanDate(dob),
        primaryStreet: street1,
        primaryStreetTwo: street2,
        primaryCity: city,
        primaryRegion: state,
        primaryPostal: postal,
        primaryCountry: country,
        language: language,
        product: product,
        hasLogin: email && email.length > 0 ? true : false,
        isPrimary: isPrimary,
        isValid: valid,
        externalID: externalId,
        message: message
    };
    if (member.isPrimary) { member.dependents = [] }
    return member;
}

export const regionAbbreviation = (region) => {
    try {
        return region.length <= 2 ? region : states.filter(x => x.name == region)[0].abbreviation;
    } catch (error) {
        return false;
    }
}