import VueCompositionAPI from "@vue/composition-api";
import Maska from "maska";
import { createPinia, PiniaVuePlugin } from "pinia";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue from "vue";
import vueCountryRegionSelect from "vue-country-region-select";
import VuePapaParse from "vue-papa-parse";
import VueSweetalert2 from "vue-sweetalert2";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { audience, clientId, domain } from "../auth_config";
import App from "./App.vue";
import { Auth0Plugin } from "./auth";
import vuetify from "./plugins/vuetify";
import router from "./router";

const pinia = createPinia();

const VueTelInputOptions = {
  mode: "international",
  onlyCountries: [
    "AR", // Argentina
    "US", // United States
    "CA", // Canada
    "CL", // Chile
    "CR", // Costa Rica
    "PR", // Puerto Rico
    "VI", // Virgin Islands
    "BR", // Brazil
  ],
  defaultCountry: "US",
  autoFormat: true,
  validCharactersOnly: true,
};

Vue.use(Maska)
  .use(VuePapaParse)
  .use(VueSweetalert2)
  .use(PiniaVuePlugin)
  .use(VueCompositionAPI)
  .use(VueTelInput, VueTelInputOptions)
  .use(vueCountryRegionSelect)
  .use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
  });

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
